import React from 'react';
import { v4 as uuid } from 'uuid';

const Select = ({ value, onChange, options }) => {

    const optionItems = options.map((option) =>
        <option key={uuid()} value={option} className="text-blueGray-700">{option.charAt(0).toUpperCase() + option.slice(1)}</option>
    );

    return (
        <select
            className="bg-transparent border-b border-blueGray-300 ml-3 py-2 px-1 w-full focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:border-non"
            onChange={onChange}
            value={value}
        >

            {optionItems}
        </select>
    );
}




export default Select;
import React from 'react';

const PopupTextarea = ({ type, name, value, onChange }) => (
    <textarea
        className="w-full h-52 mb-8 bg-indigo-700 rounded-md text-blueGray-50 p-6 overflow-y-auto overflow-x-hidden"
        type={type}
        name={name}
        value={value}
        onChange={onChange} />
);



export default PopupTextarea;
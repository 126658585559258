import React, { useRef, useEffect, useState } from 'react';
import { Power2, gsap } from "gsap/all";


const PopupBackgroundOverlay = ({ animateIn }) => {

    let popupBackgroundOverlayItem = useRef(null);
    const tl = gsap.timeline();

    const [opacity, setOpacity] = useState("opacity-0")


    useEffect(() => {

        if(animateIn === null) { // Do nothing if we what the component to be re-rendered but not animate in
            setOpacity("opacity-1");
        }

        // Animates in if the animateIn prop is set to true
        if (animateIn) {
            tl.fromTo(popupBackgroundOverlayItem, { opacity: 0 }, { duration: .8, opacity: .9, ease: Power2.easeOut })
        }
        // Animates out if the animateIn prop is set to false
        if (animateIn === false) {
            tl.fromTo(popupBackgroundOverlayItem, { opacity: .9 }, { duration: .4, opacity: 0, ease: Power2.easeOut })
        }

    }, [animateIn, tl, setOpacity])

    return (
        <div ref={el => { popupBackgroundOverlayItem = el }} className={`bg-blueGray-900 ${opacity} top-0  z-30 row-start-1 row-end-1 col-start-1 col-end-1`}></div>
    )
}

//opacity-90

export default PopupBackgroundOverlay;
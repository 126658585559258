
export const getTranslate = (element, axis) => {
    const style = window.getComputedStyle(element)
    const matrix = style.transform || style.mozTransform;

    const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(', ');

    const x = matrixValues[4]
    const y = matrixValues[5]

    if(axis === 'x'){
        return x;
    } 

    if(axis === 'y') {
        return y;
    }

}


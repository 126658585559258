import React from 'react';
import convertToREM from '../../../utils/convertToREM';
import TimelineLayerMenuItem from './TimelineLayerMenuItem';

const TimelineLayerMenu = ({ width, layers, setSelectKeyframe, setSelectLayer,  setLockLayer }) => {

    
    const lastItem = layers.length - 1;

    const layerItems = layers.map((item, i) => {
        if (i === lastItem) {
            return (
                <TimelineLayerMenuItem
                    key={item.id}
                    id={item.id}
                    isLocked={item.isLocked}
                    isSelected={item.isSelected}
                    keyframes={item.keyframes}
                    setSelectLayer={setSelectLayer}
                    setSelectKeyframe={setSelectKeyframe}
                    setLockLayer={setLockLayer}
                    isLastItem={true}
                >{item.name}</TimelineLayerMenuItem>
            )
        } else {
            return (
                <TimelineLayerMenuItem
                    key={item.id}
                    id={item.id}
                    isLocked={item.isLocked}
                    isSelected={item.isSelected}
                    keyframes={item.keyframes}
                    setSelectLayer={setSelectLayer}
                    setSelectKeyframe={setSelectKeyframe}
                    setLockLayer={setLockLayer}
                >{item.name}</TimelineLayerMenuItem>
            )
        }
    });



    // If SVG has been imported, render with layers
    if (layers.length > 0) {
        return (
            <div style={{ width: convertToREM(width) }} className="border-blueGray-900  pt-11  top-0 z-10">
                {layers.length > 0 && layerItems}
            </div>
        )
    }

    // If SVG hasn't been imported, just render an empty div
    return (
        <div className="w-timelineLayerMenu border-blueGray-900 border-t border-r h-full"></div>
    )
}



export default TimelineLayerMenu;


export default function drawBoundingBox(element, parent) {

    // get the bounding box and matrix
    var bbox = element.getBoundingClientRect();

    var div = document.createElement("div");
    div.classList.add("boundingBox", "absolute", "border", "border-indigo-500" );

    div.style.left = `${bbox.left}px`;
    div.style.top = `${bbox.top}px`;
    div.style.width = `${bbox.width}px`;
    div.style.height = `${bbox.height}px`;

    parent.appendChild(div);
}
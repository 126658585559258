import React, { useState, } from 'react';
import { CheckIcon } from '@heroicons/react/solid';

//import { MainAppContext } from '../../api/context/main.context';
import { PopupHeading, PopupPara, PopupParaUppercase } from '../atoms/Popup/PopupText';
import PopupContainer from '../atoms/Popup/PopupContainer';
import PopupBackgroundOverlay from '../atoms/Popup/PopupBackgroundOverlay';
// import CloseButton from '../atoms/Button/CloseButton';
import PopupButtonPrimary from '../atoms/Button/PopupButtonPrimary';


const DisclaimerPopup = ({ setShowDisclaimerPopup, setShowImportPopup }) => {


    // Get app context
    //const { globalState } = useContext(MainAppContext);


    // Set state so popup can animate out
    const [animateIn, setAnimateIn] = useState(true);



    return (
        <div className="grid grid-cols-1 grid-rows-1 w-screen h-screen absolute top-0">
            <PopupContainer animateIn={animateIn}>
                {/* <CloseButton
                    onClick={() => {
                        setAnimateIn(false); // This will update the state so the popup will animate out
                        setTimeout(() => { // setAnimateIn will only hide the popup but  to stop it from rendering as we want to close it
                            // setShowDisclaimerPopup(false)
                            // Show import popup in parent component

                        }, 500)
                    }} /> */}
                <PopupHeading>Disclaimer</PopupHeading>
                <PopupParaUppercase>This is still a developmental application:</PopupParaUppercase>
                <div className="max-h-60 overflow-y-auto mb-8 bg-indigo-700 rounded-md p-6">
                    <PopupPara leftAlign={true}>Please read this disclaimer carefully before using the animateThatSVG application.</PopupPara>
                    <PopupPara leftAlign={true}>Welcome to animateThatSVG. By clicking “I accept” and accessing this application, you agree to the following statement.</PopupPara>
                    <PopupPara leftAlign={true}>This application (animateThatSVG) is for educational purposes only and is also still in development. Because of this animateThatSVG it is not suitable for any commercial applications. animateThatSVG will not be responsible for any data or time loss. We strongly recommend you export and save your animated SVG code frequently outside of the application. Use at your own risk.</PopupPara>
                    <PopupPara leftAlign={true}>Lastly, thank you for taking the time to use this application, we hope you enjoy it.</PopupPara>
                </div>
                <PopupButtonPrimary
                    styleOverride="ml-auto"
                    onClick={() => {
                        setAnimateIn(false); // This will update the state so the popup will animate out

                        setTimeout(() => { // setAnimateIn will only hide the popup but wen to stop it from rendering as we want to close it
                            setShowDisclaimerPopup(false)
                            setShowImportPopup(true)
                        }, 500)
                    }}>
                    <CheckIcon className="h-6 w-6 mr-2 text-indigo-600" />
                I accept
            </PopupButtonPrimary>
            </PopupContainer>
            <PopupBackgroundOverlay animateIn={animateIn} />
        </div>
    )
}

export default DisclaimerPopup;
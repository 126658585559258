import React from 'react';
import { PlayIcon, PauseIcon } from '@heroicons/react/solid';

const PlayButton = ({ isPlaying, handlePlay }) => {


    let icon;
    if (isPlaying) { // Show pause Icon if animation is playing
        icon = <PauseIcon className="h-12 w-12 text-blueGray-50" />
    } else {  // Show Play Icon if animation not playing
        icon = <PlayIcon className="h-12 w-12 text-blueGray-50" />
    }

    return (
        <button onClick={handlePlay} className="p-2 mx-2 bg-indigo-500 rounded-full">
            {icon}
        </button>
    )
}

export default PlayButton;
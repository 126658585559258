import React from 'react';
// import { DocumentDuplicateIcon } from '@heroicons/react/solid';

const PopupButtonPrimary = ({ onClick, children, styleOverride}) => {

    return (
        <button onClick={onClick} className={` ${styleOverride} btn btn-popupPrimary flex items-center self-start mr-3`}>
            {/* <DocumentDuplicateIcon className="h-6 w-6 mr-2 text-indigo-600" /> */}
            {children}
        </button>
    )
}

export default PopupButtonPrimary;


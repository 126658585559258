import React from 'react';


const PopupCodeBox = ({ children }) => {

    return (
        <code className="w-full h-52 mb-8 bg-indigo-700 rounded-md text-blueGray-50 p-6 overflow-y-auto overflow-x-hidden">
            {children}
        </code>
    )
}

export default PopupCodeBox;
import React, { useRef, useEffect } from 'react';
import { gsap, Power2, Back } from "gsap/all";
import { ExclamationCircleIcon, InformationCircleIcon } from '@heroicons/react/solid';

const Toast = ({ type, children }) => {

    let toastItem = useRef(null);
    const tl = gsap.timeline();

    // Animate toast in and out
    useEffect(() => {
        tl.fromTo(toastItem, { y: 0, opacity: 0 }, { y: - 20, duration: .8, opacity: 1, ease: Back.easeOut })
        tl.fromTo(toastItem, { y: -20, opacity: 1 }, { y: 0, duration: .4, opacity: 0, ease: Power2.easeIn, delay: 1 })
    }, [tl])


    let content;

    if (type === "error" || type === undefined) { // If type is error or is undefined make an error toast
        content =
            <div className="flex flex-row p-4  bg-red-400 rounded-full">
                <ExclamationCircleIcon className="h-6 w-6 text-blueGray-50 mr-3" />
                <p className="text-base text-blueGray-50">{children}</p>
            </div>
    }

    if (type === "info") { // If type is info make an info toast
        content =
            <div className="flex flex-row p-4 bg-teal-500 rounded-full">
                <InformationCircleIcon className="h-6 w-6 text-blueGray-50 mr-3" />
                <p className="text-base text-blueGray-50">{children}</p>
            </div>
    }

    return (
        <div className="flex absolute opacity-0 bottom-0 w-full z-40 justify-center" ref={el => { toastItem = el }} >
            {content}
        </div>
    )

}


export default Toast;
import React from 'react';
import { FastForwardIcon as FastForwardIconOutline} from '@heroicons/react/outline';
import { FastForwardIcon as FastForwardIconSolid } from '@heroicons/react/solid';

const FastForwardButton = ({ fastForward, handleFastForward }) => {


    let icon;
    if (fastForward) { // Show solid Icon if animation is active
        icon = <FastForwardIconSolid className="h-8 w-8 text-blueGray-700" />
    } else {  // Show outline Icon if animation is active
        icon = <FastForwardIconOutline className="h-8 w-8 text-blueGray-700" />
    }

    return (
        <button onClick={handleFastForward} className="p-2 bg-transparent">
            {icon}
        </button>
    )
}

export default FastForwardButton;
import React from 'react';

const StandardInput = ({type, name, value, onChange}) => (
    <input 
    className="bg-transparent border-b border-blueGray-300 ml-3 py-2 px-1 w-full focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:border-non" 
    type={type} 
    name={name} 
    value={value} 
    onChange={onChange} />
);

export default StandardInput;
import React from 'react';
import { XIcon } from '@heroicons/react/outline';

const CloseButton = ({ onClick, color = 'text-indigo-400', styles = 'absolute top-5 right-5' }) => {

    return (
        <button onClick={onClick} className={styles}>
            <XIcon className={`h-6 w-6 ${color}`} />
        </button>
    )
}

export default CloseButton;

const defaultSVG = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 511 512" style="enable-background:new 0 0 511 512;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#94A3B8;}
	.st1{fill:#CBD5E1;}
	.st2{fill:#6366F1;}
	.st3{fill:#4F46E5;}
	.st4{fill:#4338CA;}
	.st5{fill:#475569;}
	.st6{fill:#334155;}
</style>
<g id="Layer_1">
	<path class="st0" d="M255.4,233.2L255.4,233.2c-0.7,0-1.3-0.6-1.3-1.3V224c0-0.7,0.6-1.3,1.3-1.3l0,0c0.7,0,1.3,0.6,1.3,1.3v7.9
		C256.8,232.6,256.2,233.2,255.4,233.2z"/>
	<path class="st0" d="M239,239.6L239,239.6c-0.5,0.5-1.4,0.5-1.9,0l-5.6-5.6c-0.5-0.5-0.5-1.4,0-1.9h0c0.5-0.5,1.4-0.5,1.9,0
		l5.6,5.6C239.6,238.2,239.6,239.1,239,239.6z"/>
	<path class="st0" d="M288.9,256L288.9,256c0,0.7-0.6,1.3-1.3,1.3h-7.9c-0.7,0-1.3-0.6-1.3-1.3l0,0c0-0.7,0.6-1.3,1.3-1.3h7.9
		C288.3,254.7,288.9,255.3,288.9,256z"/>
	<path class="st0" d="M232.7,256L232.7,256c0,0.7-0.6,1.3-1.3,1.3h-7.9c-0.7,0-1.3-0.6-1.3-1.3l0,0c0-0.7,0.6-1.3,1.3-1.3h7.9
		C232.1,254.7,232.7,255.3,232.7,256z"/>
	<path class="st0" d="M271.7,239.8L271.7,239.8c-0.5-0.5-0.5-1.4,0-1.9l5.6-5.6c0.5-0.5,1.4-0.5,1.9,0l0,0c0.5,0.5,0.5,1.4,0,1.9
		l-5.6,5.6C273,240.3,272.2,240.3,271.7,239.8z"/>
	<path class="st0" d="M239.2,272.3L239.2,272.3c0.5,0.5,0.5,1.4,0,1.9l-5.6,5.6c-0.5,0.5-1.4,0.5-1.9,0l0,0c-0.5-0.5-0.5-1.4,0-1.9
		l5.6-5.6C237.9,271.7,238.7,271.7,239.2,272.3z"/>
	<path class="st0" d="M255.4,289.3L255.4,289.3c-0.7,0-1.3-0.6-1.3-1.3v-7.9c0-0.7,0.6-1.3,1.3-1.3l0,0c0.7,0,1.3,0.6,1.3,1.3v7.9
		C256.8,288.7,256.2,289.3,255.4,289.3z"/>
	<path class="st0" d="M271.7,272.3L271.7,272.3c0.5-0.5,1.4-0.5,1.9,0l5.6,5.6c0.5,0.5,0.5,1.4,0,1.9l0,0c-0.5,0.5-1.4,0.5-1.9,0
		l-5.6-5.6C271.2,273.6,271.2,272.8,271.7,272.3z"/>
</g>
<g id="Layer_2_1_">
	<path class="st1" d="M339.3,153.8H171.7l27.9,195.7c0.7,5,5,8.7,10,8.7h92c5,0,9.3-3.7,10-8.7L339.3,153.8z"/>
	<path class="st0" d="M256.3,153.8l-29.1,204.4H246c5,0,9.3-3.7,10-8.7l27.9-195.7H256.3z"/>
	<path class="st2" d="M190.8,318.1l-17.5-122.8c-0.4-2.9,1.8-5.4,4.7-5.4h155c2.9,0,5.1,2.6,4.7,5.4l-17.5,122.8
		c-0.3,2.3-2.3,4.1-4.7,4.1H195.5C193.1,322.1,191.1,320.4,190.8,318.1z"/>
	<path class="st3" d="M273.5,189.9h-22l-18.8,132.3h23.3c2.4,0,4.4-1.7,4.7-4.1l17.5-122.8C278.6,192.4,276.4,189.9,273.5,189.9z"/>
	<path class="st4" d="M279.7,231.8c-10.4-10.4-29.7-8-43,5.4c-13.4,13.4-15.8,32.6-5.4,43c10.4,10.4,29.7,8,43-5.4
		C287.7,261.5,290.1,242.2,279.7,231.8z"/>
	<path class="st3" d="M279.7,231.9c-1.1-1.1-2.3-2.1-3.6-2.9c-6,3.3-17.6,11-20.8,23c-1.4,5.2-6.5,11.4-14.6,18
		c-4.7,3.8-9.3,6.7-11.3,8c0.6,0.8,1.3,1.6,2,2.4c1,1,2.1,1.9,3.3,2.7c7-4.4,24-16.3,27.5-29.1c3-11.1,16.1-18,19.5-19.7
		C281.1,233.4,280.4,232.6,279.7,231.9z"/>
</g>
<g id="Layer_3">
	<path class="st5" d="M322,116.3H189c-1.9,0-3.5,1.3-3.9,3.2l-6.1,29.6h153l-6.1-29.6C325.5,117.6,323.9,116.3,322,116.3z"/>
	<path class="st6" d="M327.5,127.6l-1.7-8.1c-0.4-1.9-2-3.2-3.9-3.2h-23.8l1.3,6.3c1.1,5.1-2.9,7.6-8.1,7.6H182.9l-3.9,18.9h153
		L327.5,127.6z"/>
	<path class="st5" d="M344.4,165.5H166.6c-3.2,0-5.9-2.6-5.9-5.9V144c0-3.2,2.6-5.9,5.9-5.9h177.7c3.2,0,5.9,2.6,5.9,5.9v15.6
		C350.2,162.9,347.6,165.5,344.4,165.5z"/>
	<path class="st6" d="M344.4,138.2H323v4.9c0,9.3-4.6,14.5-13.9,14.5H169.6c-3.2,0-5.9-2.6-5.9-5.9l-2.9,8c0,3.2,2.6,5.9,5.9,5.9
		h177.7c3.2,0,5.9-2.6,5.9-5.9V144C350.2,140.8,347.6,138.2,344.4,138.2z"/>
</g>
</svg>`;

export default defaultSVG;
import React from 'react';

const ButtonSecondary = ({ onClick, children, hasIcon }) => {

    if (hasIcon) {
        return (
            <button onClick={onClick} className="btn btn-secondary mr-3 flex items-center">
                {children}
            </button>
        )
    }

    return (
        <button onClick={onClick} className="btn btn-secondary mr-3">
            {children}
        </button>
    )
}



export default ButtonSecondary;
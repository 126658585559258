import React from 'react';


const Logo = () => {
    return(
        <div className="flex items-center">
            {/* <img src={process.env.PUBLIC_URL + '/images/logo/logo.svg'} /> */}
            <img src='/images/logo/logo.svg' alt="AnimateThatSVG Logo" className="w-12 h-12"  />
            <h1 className="text-xl font-extrabold text-blueGray-50 ml-3">AnimateThatSVG</h1>
        </div>
    )
}

export default Logo;
import React from 'react';
import PlayButton from '../../atoms/Button/PlayButton';
import FastForwardButton from '../../atoms/Button/FastForwardButton';
import RewindButton from '../../atoms/Button/RewindButton';


const PlayerControls = ({ isPlaying, handlePlay, fastForward, handleFastForward, rewind, handleRewind }) => {
    return (
        <div className="flex align-middle m-5">
            <RewindButton rewind={rewind} handleRewind={handleRewind}/>
            <PlayButton handlePlay={handlePlay} isPlaying={isPlaying} />
            <FastForwardButton fastForward={fastForward} handleFastForward={handleFastForward}/>
        </div>
    )
}

export default PlayerControls;


import React, { useRef, useEffect, useState } from 'react';
import {gsap, Back, Power2} from "gsap/all";


const PopupContainer = ({ children, animateIn }) => {

    let popupContainerItem = useRef(null);
    const tl = gsap.timeline();

    const [opacity, setOpacity] = useState("opacity-0");

    useEffect(() => {
        if(animateIn === null) { // Do nothing if we what the component to be re-rendered but not animate in
            setOpacity("opacity-1");
        }
     
        // Animates in if the animateIn prop is set to true
        if (animateIn) {
            tl.fromTo(popupContainerItem, { y: 300, opacity: 0 }, { y: 0, duration: .8, opacity: 1, ease: Back.easeOut, delay: .2 })
        }
        // Animates out if the animateIn prop is set to false
        if (animateIn === false) {
            tl.fromTo(popupContainerItem, { y: 0, opacity: 1 }, { y: 300, duration: .4, opacity: 0, ease: Power2.easeInOut})
        }

    }, [animateIn, tl, setOpacity])


    return (
        <div ref={el => { popupContainerItem = el }} className={`${opacity} flex flex-col items-center p-9 bg-indigo-600 rounded-2xl z-40 max-w-xl row-start-1 row-end-1 col-start-1 col-end-1 justify-self-center self-center`}>
            {children}
        </div>
    )
}

export default PopupContainer;

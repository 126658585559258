import React from 'react';
import { RewindIcon as RewindIconOutline } from '@heroicons/react/outline';
import { RewindIcon as RewindIconIconSolid } from '@heroicons/react/solid';

const RewindButton = ({ rewind, handleRewind }) => {

    let icon;
    if (rewind) { // Show solid Icon if animation is active
        icon = <RewindIconIconSolid className="h-8 w-8 text-blueGray-700" />
    } else {  // Show outline Icon if animation is active
        icon = <RewindIconOutline className="h-8 w-8 text-blueGray-700" />
    }

    return (
        <button onClick={handleRewind} className="p-2 bg-transparent">
            {icon}
        </button>
    )
}

export default RewindButton;
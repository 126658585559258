import React from 'react';

export const PopupHeading = ({children}) => {
    return(
        <h2 className="text-2xl font-bold mb-4 text-blueGray-50">{children}</h2>
    )
}


export const PopupParaUppercase = ({ children }) => {
    return (
        <p className="text-base text-center mb-4 text-blueGray-200 uppercase">{children}</p>
    )
}

export const PopupPara = ({ children, leftAlign }) => {
    return (
        <p className={`text-base ${leftAlign ? '' : 'text-center'} mb-8 text-blueGray-200`}>{children}</p>
    )
}

export const PopupParaSmall = ({ children }) => {
    return (
        <p className="text-sm text-center mb-8 text-blueGray-200">{children}</p>
    )
}

export const PopupLink = ({ children, href }) => {
    return (
        <a className="text-sm text-center mb-8 text-blueGray-200 underline" href={href} target="_blank" rel="noreferrer">{children}</a>
    )
}




// export const PopupLabel = ({children}) => { // Might not need this
//     return (
//         <label>{children}</label>
//     )
// }






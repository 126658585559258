import { gsap } from "gsap/all";
import getEase from './getEase';




export default function updateAnimationOnScreen(layer, currentTimeIncrement, isPlaying = true) {



    let animationStartTime;


    // Check if there are any keyframes on that layer if not return
    if (layer.keyframes.length < 1) {
        return;
    }


    // Get the last keyframe increment
    const lastKeyframeIncrement = layer.keyframes.slice(-1).pop().increment;

   
    if (currentTimeIncrement === 0) {
        /**
         * Note: if the Timeline's timeScale  is exactly 0 (it will be if  lastKeyframeIncrement is 0) 
         * when play() is called, it will be changed to 1 (otherwise it wouldn't play). If we are going to 
         * tween it up from 0 we can set it to a very small number before calling play() 
         * like tl.timeScale(tl.timeScale() || 0.001).play() so that it doesn't jump to 1
         */
        animationStartTime = 0.001;
    } 
    else if (currentTimeIncrement > lastKeyframeIncrement) {
        /**
        * Set animation start time to 1 (finished) if the currentTimeIncrement
        * is more than the lastKeyframeIncrement, this means the PlayHead
        * has gone passed the last frame
        */
        animationStartTime = 1;
    }
    else {
        animationStartTime = (1 / lastKeyframeIncrement) * currentTimeIncrement;
    }

    


    // Get the layer ID
    let layerId = `${layer.id}`
    const SVGLayerOnScreen = document.getElementById(layerId);



    // Make new timeline but set it to pause
    const tl = gsap.timeline({ paused: true });


    let lastIncrement = 0;
    layer.keyframes.forEach(keyframe => {
        tl.to(SVGLayerOnScreen,
            {
                rotation: keyframe.rotation,
                opacity: keyframe.opacity,
                scaleX: keyframe.scaleX,
                scaleY: keyframe.scaleY,
                x: keyframe.translateX,
                y: keyframe.translateY,
                transformOrigin: "center center",
                duration: (keyframe.increment - lastIncrement) / 10,
                ease: getEase(keyframe.ease)
            }
        );
        lastIncrement = keyframe.increment;
    });


    // This will start the animation from 
    tl.progress(animationStartTime);





    /**
     * If is playing is true play the animation
     */
    if (isPlaying) {
        tl.play();
    }



}
import React, { useReducer, createContext, } from 'react';
import reducer from '../reducers/main.reducer';


// Build initial state
const initialState = {
    initialSVGImport: true,
    loading: false,
    importSuccess: false,
    importError: false,
    importedSVGCode: '',
    exportSVGCode: '',
    error: '',
    playHeadStartingPosition: 356,
    playHeadCurrentPosition: 356,
    currentTimeIncrement: 0,
    currentKeyframeSelected: null,
    layers: [],
    animationPlaying: false,
    fastForwardAnimation: false,
    rewindAnimation: false,
}

export const MainAppContext = createContext();

export const MainAppProvider = (props) => {

    const [globalState, dispatch] = useReducer(reducer, initialState);

    return (
        <MainAppContext.Provider value={{ globalState, dispatch }}>
            {props.children}
        </MainAppContext.Provider>
    );
}
import React from 'react';
import StandardInput from '../../atoms/Input/StandardInput';
import { Label } from '../../atoms/Text/Text';

const StandardInputWithLabel = ({ type, name, onChange, value, children }) => (
    <Label>
        <span className="w-28 text-left">{children}</span>
        <StandardInput type={type} name={name} value={value} onChange={onChange} />
    </Label>

);

export default StandardInputWithLabel;
import React from 'react';
import { LockOpenIcon, LockClosedIcon, ClockIcon } from '@heroicons/react/outline';
import drawBoundingBox from '../../../utils/drawBoundingBox';
import Keyframe from '../../atoms/Keyframe/KeyFrame';


const TimelineLayerMenuItem = ({ id, children, isLocked, isSelected, keyframes, isLastItem, setSelectLayer, setSelectKeyframe, setLockLayer }) => {


    /**
     * This allow the user to select then layer when clicked on 
     * when its not locked
     */
    const handleClick = () => {
        if (!isLocked) {
            setSelectLayer(id);

            // Remove the current selected bounding box of layer in the SVG
            document.querySelector('.boundingBox').remove();


            // Then add new bounding box to SVG based on the current layer ID
            drawBoundingBox(document.getElementById(`${id}`), document.getElementById('importedSVG'));
        }
    }

    const handleClickLock = (e) => {
        e.stopPropagation();

        if (isLocked) { // if layer is already locked unlock it
            setLockLayer(false);
            return;
        }

        setLockLayer(id); // lock layer by passing id to it
        setSelectKeyframe(false) // this will deselect any keyframes on that layer if they are selected 
    }

    // Render all of the keyframes 
    const keyframesItems = keyframes.map((keyframe) =>
        <Keyframe
            key={keyframe.id}
            id={keyframe.id}
            isSelected={keyframe.isSelected}
            positionX={keyframe.keyframePosition}
            setSelectKeyframe={setSelectKeyframe}
            isLocked={isLocked}
        />
    );


    if (isLocked) {
        return (
            <div onClick={handleClick} className="flex cursor-pointer">
                <div className={`flex items-center p-3  w-timelineLayerMenuItem border-blueGray-900 border-t border-r ${isLastItem ? 'border-b' : ''}  text-base text-blueGray-500`}>
                    <LockClosedIcon onClick={handleClickLock} className="lockIcon h-6 w-6 text-blueGray-500 mr-4" />
                    <div className="select-none">{children}</div>
                    {keyframes.length > 0 &&
                        <ClockIcon className="h-6 w-6 text-blueGray-500 ml-auto" />
                    }
                </div>
                <div className="w-full relative">{keyframesItems}</div>
            </div>
        )
    }

    if (isSelected) {
        return (
            <div onClick={handleClick} className="flex cursor-pointer">
                <div className={`flex items-center  p-3  w-timelineLayerMenuItem border-blueGray-900 border-t border-r ${isLastItem ? 'border-b' : ''} bg- text-base relative text-blueGray-50 bg-indigo-500 bg-opacity-50`}>
                    <LockOpenIcon onClick={handleClickLock} className="lockIcon h-6 w-6 text-blueGray-50 mr-4" />
                    <div className="select-none">{children}</div>
                    {keyframes.length > 0 &&
                        <ClockIcon className="h-6 w-6 text-blueGray-50 ml-auto" />
                    }
                </div>
                <div className="w-full bg-indigo-500 bg-opacity-50 relative border-t border-b border-blueGray-700">{keyframesItems}</div>
            </div>
        )
    }

    return (
        <div onClick={handleClick} className="flex cursor-pointer">
            <div className={`flex items-center p-3 w-timelineLayerMenuItem  border-blueGray-900 border-t border-r ${isLastItem ? 'border-b' : ''}  text-base text-blueGray-50`}>
                <LockOpenIcon onClick={handleClickLock} className="lockIcon h-6 w-6 text-blueGray-50 mr-4" />
                <div className="select-none">{children}</div>
                {keyframes.length > 0 &&
                    <ClockIcon className="h-6 w-6 text-blueGray-50 ml-auto" />
                }
            </div>
            <div className="w-full relative">{keyframesItems}</div>
        </div>
    )
}

export default TimelineLayerMenuItem;

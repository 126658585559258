import formatter from 'html-formatter';


const getEase = (ease) => {
    if (ease === 'easeNone') {
        return 'Power2.easeNone';
    } else if (ease === 'easeIn') {
        return 'Power2.in';
    } else if (ease === 'easeOut') {
        return 'Power2.out';
    } else if (ease === 'easeInOut') {
        return 'Power2.inOut';
    }
}




export default function makeExportAnimation(layers, importedSVGCode) {

    const timelines = [];
    const layerSelectors = [];
    //const keyframes = [];
    const tweens = [];

    let count = 0;
    layers.forEach(layer => {
        // Increment count by one
        count++;
        // Make new timeline and add it to the timelines array
        const timeline = `const tl${count} = gsap.timeline(); `
        timelines.push(timeline);


        // Make layer selector (these are the layers in the exported SVG that are animated) 
        const layerSelector = `const layer${count} = document.getElementById('${layer.id}');`
        layerSelectors.push(layerSelector);



        // Make animation if keyframes are greater the 0 (if their are keyframes)
        if (layer.keyframes.length > 0) {
            let lastIncrement = 0;

            // Make every keyframe into a tween 
            layer.keyframes.forEach(keyframe => {
                const newTween = `
                tl${count}.to(layer${count},{
                        rotation: ${keyframe.rotation},
                        opacity: ${keyframe.opacity},
                        scaleX: ${keyframe.scaleX}, 
                        scaleY: ${keyframe.scaleY},
                        x: ${keyframe.translateX},
                        y: ${keyframe.translateY},
                        transformOrigin:"center center",
                        duration: ${(keyframe.increment - lastIncrement) / 10},
                        ease: ${getEase(keyframe.ease)}
                    }
                );
                `;
                tweens.push(newTween);
                lastIncrement = keyframe.increment;
            });
        }



    });


    const html = `
    <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.6.1/gsap.min.js" integrity="sha512-cdV6j5t5o24hkSciVrb8Ki6FveC2SgwGfLE31+ZQRHAeSRxYhAQskLkq3dLm8ZcWe1N3vBOEYmmbhzf7NTtFFQ=="
    crossorigin="anonymous"></script>
    <script>
    window.onload = () => {
        ${timelines.join(' ')}
        ${layerSelectors.join(' ')}
        ${tweens.join(' ')}
    };
    </script>

    ${importedSVGCode}
    `


    return (
        formatter.render(html)
    )
}


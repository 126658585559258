import React, { useRef, useEffect } from 'react';
import { gsap } from "gsap/all";
import isOverlapping from '../../../utils/isOverlapping';






const PlayHead = ({ positionX, setCurrentTimeIncrement, isPlaying, fastForward }) => {


  
    let playHeadItem = useRef(null);
    const bar1Anim = useRef(gsap.timeline({ paused: true }));


    /**
     * This code will check to see if the PlayHead is overlapping an increment as we 
     * need to find out what increment the PlayHead is currently over when its moving 
     * (not when user is clicks on an increment because we already know that). Please not this 
     * could is very inefficient, as it this useEffect runs every time the component is updated and 
     * it runs a document.querySelectorAll to get all the increment classNames When it updates. 
     */
    useEffect(() => {
        // document.querySelectorAll('.TimeIncrement').forEach(increment => {
        //     if(isOverlapping(playHeadItem, increment)) {
        //         console.log(increment.getAttribute('data-key'))
        //     }
        // });

        const increments = document.querySelectorAll('.TimeIncrement');
        for (let i = 0; i < increments.length; i++) {
            if (isOverlapping(playHeadItem, increments[i])) {
                setCurrentTimeIncrement(parseInt(increments[i].getAttribute('data-key')));
            }
        }
    })

    /**
        * This is what animates the PlayHead
        */
    // This will run only whe the value of isPlaying is updated
    useEffect(() => {


        if(isPlaying && fastForward) {
            bar1Anim.current.play();
            bar1Anim.current.timeScale(2)
            bar1Anim.current.repeat(-1);
        }

        if (isPlaying && !fastForward) { // play normal speed 
            bar1Anim.current.play();
            bar1Anim.current.repeat(-1);
        }

        // if (isPlaying && fastForward) { // play 2x speed
        //     bar1Anim.current.play();
        //     bar1Anim.current.timeScale(2);
        //     bar1Anim.current.repeat(-1);
        // }


        if (!isPlaying) {
            bar1Anim.current.pause();
            bar1Anim.current.clear();
        }

    }, [isPlaying, positionX, fastForward]);

    useEffect(() => {
        bar1Anim.current.fromTo(playHeadItem, { x: positionX }, { x: 5594, duration: 30, ease: "none" });
    }, [positionX, bar1Anim]);



    return (
        <div id="PlayHead" ref={el => { playHeadItem = el }} style={{ transform: `translate(${positionX}px, 0px)` }} className="absolute flex h-full  flex-col items-center w-2 z-20 mt-7">
            <img src='/images/playhead/playhead_tip.svg' alt="playhead" className="w-2 h-auto" />
            <div className="bg-red-500 w-0.5 h-full -mt-1"></div>
        </div>
    )
}

export default PlayHead;
import React from 'react';

export const Heading = ({children}) => {
    return(
        <h2 className="text-xl font-bold mb-4 text-blueGray-50">{children}</h2>
    )
}

export const SubHeading = ({children}) => {
    return(
        <h2 className="text-base mb-4 text-blueGray-50">{children}</h2>
    )
}

export const Para = ({ children }) => {
    return (
        <p className="text-base text-center mb-8 text-blueGray-200">{children}</p>
    )
}

export const ParaSmall = ({ children }) => {
    return (
        <p className="text-sm text-center mb-8 text-blueGray-200">{children}</p>
    )
}

export const Link = ({ children, href }) => {
    return (
        <a className="text-sm text-center mb-8 text-blueGray-200 underline" href={href}>{children}</a>
    )
}


export const Label = ({children}) => { // Might not need this
    return (
        <label className="flex items-center  text-sm text-center mb-4 text-blueGray-200">{children}</label>
    )
}

import React, { useContext, useRef, useEffect } from 'react';
import { MainAppContext } from '../../../api/context/main.context';

const TimelineScale = ({ setTimelineScaleWidth, setCurrentTimeIncrement, setSelectKeyframe }) => {


    // Get app context
    const { globalState, dispatch } = useContext(MainAppContext);


    /**
     * The TimelineLayerMenu width is based on the width of this
     * component, this code will get that and pass it uo to the parent
     * component (the TimeLine), there is a note in that component that
     * explains why we can't just hard code the width into the TimelineLayerMenu
     */
    let timelineScaleItem = useRef(null);
    useEffect(() => {
        const elementWidth = timelineScaleItem.offsetWidth;
        // Get margin getComputedStyle will return a string with px so we have to remove that
        let elementLeftMargin = window.getComputedStyle(timelineScaleItem).marginLeft;
        elementLeftMargin = elementLeftMargin.substring(0, elementLeftMargin.length - 2);
        setTimelineScaleWidth(elementWidth + parseInt(elementLeftMargin))
    }, [setTimelineScaleWidth])



    const handleClick = (e) => {

        // Set PlayHead starting position to the increment that has been selected
        let newPlayHeadPosition = (globalState.playHeadStartingPosition + e.target.offsetLeft) - 18;
        dispatch({ type: 'UPDATE_PLAYHEAD_STARTING_POSITION', payload: newPlayHeadPosition })

        /**
          * Get the time for the increment that has been selected,
          * then pass it up to the parent component (Timeline) using setCurrentTimeIncrement.
          * This will push this value to the global context
          */
        const incrementTime = e.target.getAttribute('data-key');
        setCurrentTimeIncrement(parseInt(incrementTime));


        // Deselect current selected keyframe 
        setSelectKeyframe(null)

    }


    /**
     * This will build our scale, it renders different increments based on 
     * weather a number is a multiple of 10. If it is, it will render the tallest 
     * increment with a number. If its multiple of 5 but not 10, it will render the second 
     * tallest increment. If it is none of these it will just render the smallest line
     */
    const scale = [];
    for (let i = 0; i < 301; i++) {
        if (i % 10 === 0) {
            scale.push(
                <div onClick={handleClick} key={i} data-key={`${i.toString()}`} className="TimeIncrement flex flex-col mr-3 items-center cursor-pointer">
                    <span data-key={`${i.toString()}`} className="text-blueGray-50 text-sm">{i / 10}s</span>
                    <div data-key={`${i.toString()}`} className="h-4 w-1 bg-blueGray-500"></div>
                </div>
            )
        } else if ((i % 5 === 0) && (i !== 0) && (i % 10 !== 0)) {
            scale.push(
                <div onClick={handleClick} key={i} data-key={`${i.toString()}`} className="TimeIncrement h-3 w-1 bg-blueGray-500 mr-3 cursor-pointer"></div>
            );
        } else {
            scale.push(
                <div onClick={handleClick} key={i} data-key={`${i.toString()}`} className="TimeIncrement h-1.5 w-1 bg-blueGray-500 mr-3 cursor-pointer"></div>
            );
        }
    }


    return (
        <div ref={el => { timelineScaleItem = el }} className="flex border-b border-l border-blueGray-900 items-end pt-2 absolute ml-timelineScale pl-3 bg-blueGray-700 z-10">
            {scale}
        </div>
    );
}

export default TimelineScale;



import { v4 as uuid } from 'uuid';

const reducer = (state, action) => {
    switch (action.type) {
        case 'SVG_IMPORT_SUCCESS':
            return {
                loading: false,
                importSuccess: true,
                importError: false,
                importedSVGCode: `${action.payload}`,
                exportSVGCode: '',
                error: '',
                playHeadStartingPosition: 356,
                playHeadCurrentPosition: 356,
                currentTimeIncrement: 0,
                currentKeyframeSelected: null,
                layers: [],
                animationPlaying: false,
                fastForwardAnimation: false,
                rewindAnimation: false,
            };


        case 'SVG_IMPORT_ERROR':
            return { ...state, importError: action.payload };


        case 'UPDATE_PLAYHEAD_STARTING_POSITION':
            return { ...state, playHeadCurrentPosition: action.payload };


        case 'UPDATE_CURRENT_TIMELINE_INCREMENT':
            return { ...state, currentTimeIncrement: action.payload };

        case 'MAKE_LAYERS':
            const newLayers = [];
            let count = 0;
            action.payload.forEach(layerID => {
                count++;
                newLayers.push({ id: layerID, name: `Group ${count}`, isLocked: false, isSelected: count === 1 ? true : false, keyframes: [] },)
            })

            return { ...state, layers: newLayers }
        case 'SELECT_LAYER':
            let updatedSelectedLayers = [];
            state.layers.forEach(layer => {
                if (!layer.isLocked) { // if layers not locked
                    if (layer.id === action.payload) {
                        updatedSelectedLayers.push({ ...layer, isSelected: true })
                    } else {
                        updatedSelectedLayers.push({ ...layer, isSelected: false })
                    }
                } else {
                    updatedSelectedLayers.push(layer) // Layer is locked so do nothing and push to updatedSelectedLayers
                }
            });

            return { ...state, layers: updatedSelectedLayers };

        case 'LOCK_LAYER':
            let updatedLockLayers = [];

            // If payload is false the layer is lock so unlock it
            if (action.payload === false) {
                state.layers.forEach(layer => {
                    updatedLockLayers.push({ ...layer, isLocked: false }) // unlock layer
                })
            } else {  // Lock the layer based on the id passed into the payload 
                state.layers.forEach(layer => {
                    if (layer.id === action.payload) {
                        updatedLockLayers.push({ ...layer, isSelected: false, isLocked: true }) // unselect layer and lock it
                    } else {
                        updatedLockLayers.push(layer) // do nothing 
                    }
                });
            }


            return { ...state, layers: updatedLockLayers }

        case 'ADD_KEYFRAME':
            let updatedLayers = [];
            // Get the layer that is selected
            state.layers.forEach(layer => {
                if (layer.isSelected) {
                    /**
                     * Check if there is already a keyframe at the PlayHead position, is there
                     * is one set keyframeIsAlreadySetOnThatIncrement to true and set
                     * existingKeyframe to that keyframe, then remove it from newKeyframes, otherwise
                     * if the keyframe is not a the PlayHead position just add it to newKeyframes array
                     */
                    const newKeyframes = [];
                    let keyframeIsAlreadySetOnThatIncrement;
                    let existingKeyframe;
                    layer.keyframes.forEach((keyframe) => {
                        if (keyframe.increment === state.currentTimeIncrement) {
                            keyframeIsAlreadySetOnThatIncrement = true;
                            existingKeyframe = keyframe;
                            newKeyframes.filter(keyframe => keyframe.increment === state.currentTimeIncrement);
                        } else {
                            newKeyframes.push(keyframe)
                        }
                    });

                
                    /**
                     * Make a new array called sortedNewKeyframes add all newKeyframes to it then 
                     * add the new keyframe as well. After this sort the keyframes based on their
                     * increments. we need to do this because the keyframe will need to be in the right
                     * increment order as the animation code will use the increments to set the times in 
                     * the timeline 
                     */
                    const sortedNewKeyframes = []
                    sortedNewKeyframes.push(...newKeyframes,
                        {
                            ...action.payload,
                            /**
                             * If keyframe is at the PlayHead Position keep its id else assign a new one,
                             * if we don't do this the keyframe select will be removed a this would be 
                             * confusing to the user
                             */
                            id: keyframeIsAlreadySetOnThatIncrement && existingKeyframe.isSelected ? existingKeyframe.id : uuid(),
                            keyframePosition: state.playHeadCurrentPosition - 344,
                            increment: state.currentTimeIncrement,
                            isSelected: keyframeIsAlreadySetOnThatIncrement && existingKeyframe.isSelected ? true : false
                    })
                    sortedNewKeyframes.sort((a, b) => (a.increment > b.increment) ? 1 : -1)

                    /*
                     * This will add the new keyframes to the layer 
                     */
                    updatedLayers.push({
                        ...layer, keyframes: sortedNewKeyframes
                    });
                } else {
                    updatedLayers.push(layer) // Do nothing just add existing layer to updatedLayers
                }
            });

            return { ...state, layers: updatedLayers };


        case 'DELETE_KEYFRAME':
            let updatedLayersKeyframeDeleted = [];
            state.layers.forEach(layer => {
                const test = layer.keyframes.filter(keyframe => keyframe.id !== action.payload);
                updatedLayersKeyframeDeleted.push({ ...layer, keyframes: test })
            });

            return { ...state, layers: updatedLayersKeyframeDeleted, currentKeyframeSelected: null };

        case 'SELECT_KEYFRAME':
            let updatedLayersKeyframeSelect = [];
            let currentKeyframePosition;

            // If action.payload is equal to null or false this will deselect the keyframe
            if (action.payload === null || action.payload === false) {
                /**
                 * Update every individual keyframe in the state to FALSE, this in
                 * turn will curse a re-render of the keyframes and now none of them will 
                 * be selected
                 */
                state.layers.forEach(layer => {
                    layer.keyframes.forEach(keyframe => {
                        keyframe.isSelected = false
                    });
                    updatedLayersKeyframeSelect.push({ ...layer, keyframes: layer.keyframes });
                });
            } else {
                /**
                * Update the individual keyframe in the state, this in
                * turn will curse a re-render of the keyframes and the one that is selected
                * will have a different background color informing the user that they 
                * have clicked on it
                */
                state.layers.forEach(layer => {
                    if (!layer.isLocked) {
                        //console.log('layer is locked')
                    }
                    layer.keyframes.forEach(keyframe => {
                        if (keyframe.id === action.payload && !layer.isLocked) {
                            keyframe.isSelected = true;
                            // Get keyframe position
                            currentKeyframePosition = keyframe.keyframePosition;
                        } else {
                            keyframe.isSelected = false;
                        }
                    });
                    updatedLayersKeyframeSelect.push({ ...layer, keyframes: layer.keyframes });
                });
            }
            return {
                ...state,
                currentKeyframeSelected: action.payload,
                layers: updatedLayersKeyframeSelect,
                playHeadCurrentPosition: currentKeyframePosition ? currentKeyframePosition + 344 : state.playHeadCurrentPosition
            };

        case 'PLAY_ANIMATION':
            return { ...state, animationPlaying: action.payload };

        case 'FAST_FORWARD_ANIMATION':
            return { ...state, fastForwardAnimation: action.payload };

        case 'REWIND_ANIMATION':
            return { ...state, rewindAnimation: action.payload }
        case 'SET_EXPORT_SVG_CODE':
            /**
             * Note: we need to convert the  action.payload from a node to a string,
             * so we need to use .outerHTML to do this
             */
            return { ...state, exportSVGCode: action.payload.outerHTML }
        default:
            return state;
    }
}

// playHeadStartingPosition
export default reducer;


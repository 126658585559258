import React, { useState, useContext, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { MainAppContext } from '../../api/context/main.context';
import PlayerControls from '../molecules/PlayerControls/PlayerControls';
import { getTranslate } from '../../utils/translate';
import drawBoundingBox from '../../utils/drawBoundingBox';
//import animateOnScreen from '../../animations/animateOnScreen';
import updateAnimationOnScreen from '../../animations/updateAnimationOnScreen';

const Viewer = () => {

    // Get app context
    const { globalState, dispatch } = useContext(MainAppContext);


    const [isPlaying, setIsPlaying] = useState(false);
    const [rewind, setRewind] = useState(false);
    const [fastForward, setFastForward] = useState(false);



    /**
    * This will update the play button icon and play and pause the
    * animation
    */
    const handlePlay = (e) => {
        e.preventDefault();

        let toggleIsPlaying = !isPlaying;
        setIsPlaying(toggleIsPlaying);

        const PlayHead = document.getElementById('PlayHead')

        if (!toggleIsPlaying) {
            dispatch({ type: 'UPDATE_PLAYHEAD_STARTING_POSITION', payload: getTranslate(PlayHead, 'x') })
        }
        dispatch({ type: 'PLAY_ANIMATION', payload: toggleIsPlaying });


        // Just for testing
        if (toggleIsPlaying) {
            globalState.layers.forEach(layer => {
                updateAnimationOnScreen(layer, globalState.currentTimeIncrement, true)
            });
        }


    }

    // useEffect(() => {
    //     if (globalState.animationPlaying) {
    //         globalState.layers.forEach(layer => {
    //             //animateOnScreen(layer)  
    //             console.log(globalState.animationPlaying)
    //             updateAnimationOnScreen(layer, globalState.currentTimeIncrement, globalState.animationPlaying)
    //         });
    //     }
    // }, [globalState.animationPlaying, globalState.layers, globalState.currentTimeIncrement])


    /**
      * This will update the rewind button icon, rewind the
      * animation, update the fast-forward button icon and stop the animation
      * from fast-forwarding if it is fast-forwarding.
      */
    const handleRewind = () => {
        let toggleRewind = !rewind
        setRewind(toggleRewind);
        setFastForward(false);

        // TODO: rewind the animation 
        dispatch({ type: 'REWIND_ANIMATION', payload: toggleRewind })
    }


    /**
     * This will update the fastForward button icon, fast-forward the
     * animation, update the rewind button icon and stop the animation
     * from rewinding if it is rewinding.
     */
    const handleFastForward = () => {
        let toggleFastForward = !fastForward
        setFastForward(toggleFastForward);
        setRewind(false);

        // TODO: fast-forward the animation 
        dispatch({ type: 'FAST_FORWARD_ANIMATION', payload: toggleFastForward })
        dispatch({ type: 'PLAY_ANIMATION', payload: toggleFastForward });
    }


    /** We need to keep an eye on importedSVGCode in the globalState
     * as after it is imported in the import popup we need to display it
     */
    const [showSVG, setShowSVG] = useState(false);
    useEffect(() => {
        if (globalState.importedSVGCode !== '') {
            setShowSVG(true);
        }
    }, [globalState.importedSVGCode, setShowSVG,]);


    let importedSVG = useRef(null);
    useEffect(() => {
        if (showSVG) {


            //Set the Height and Width of the SVG
            importedSVG.childNodes[0].classList.add("h-auto", "w-full");
            importedSVG.childNodes[0].style.maxHeight = "40vh";


            let importedSVGChildren = importedSVG.childNodes[0].childNodes;
            const layerIDs = []
            let count = 0;
            importedSVGChildren.forEach(child => {

                // If child has id
                if (child.id) {

                    // Add one to count
                    count++;

                    // Give child an ID and also push that ID to the layerIDs array
                    const layerID = uuid();
                    layerIDs.push(layerID);
                    child.id = layerID;

                    // If it is the first layer add a BoundingBox to it
                    if (count === 1) {
                        drawBoundingBox(child, importedSVG);
                    }
                }

            });

            /**
             * As the imported SVG now has all of its children
             * with  unique identifiers ie '<circle id="b6b6bef9-6797-4257-a291-f9c50ee584bc"...'
             * we will need to set the Exported SVG to this imported SVG,
             * this is because the export popup runs a function that will need to use these
             * unique identifiers
             */
            dispatch({ type: 'SET_EXPORT_SVG_CODE', payload: importedSVG.childNodes[0] });

            // Then make the layers
            dispatch({ type: 'MAKE_LAYERS', payload: layerIDs })


        }
    }, [showSVG, dispatch])


    return (
        <div className="flex flex-col col-span-2 items-center pt-8">
            <div className="bg-blueGray-50 w-1/2 h-full flex items-center justify-center p-5">
                <div id="importedSVG" ref={el => { importedSVG = el }} dangerouslySetInnerHTML={{ __html: showSVG ? globalState.importedSVGCode : '' }}></div>
            </div>
            <PlayerControls
                isPlaying={isPlaying}
                handlePlay={handlePlay}
                rewind={rewind}
                handleRewind={handleRewind}
                fastForward={fastForward}
                handleFastForward={handleFastForward}
            />
        </div>
    )
}

export default Viewer;
import React, { useState, useContext, useEffect } from 'react';
import isSvg from 'is-svg';
import { ClipboardIcon } from '@heroicons/react/solid';

import { MainAppContext } from '../../api/context/main.context';
import { PopupHeading, PopupPara, PopupLink } from '../atoms/Popup/PopupText';
import PopupTextarea from '../atoms/Input/PopupTextarea';
import PopupContainer from '../atoms/Popup/PopupContainer';
import PopupSubmitInput from '../atoms/Input/PopupSubmitInput';
import PopupButtonSecondary from '../atoms/Button/PopupButtonSecondary';
import CloseButton from '../atoms/Button/CloseButton';
import PopupBackgroundOverlay from '../atoms/Popup/PopupBackgroundOverlay';
import Toast from '../molecules/Toast/Toast';
import defaultSVG from '../../defaultSVG/defaultSVG';

import ImportGuide from '../../documents/ImportGuide.pdf';



const ImportPopup = ({ setShowImportPopup }) => {

    // Get app context
    const { globalState, dispatch } = useContext(MainAppContext);

    // Set state so popup can animate out
    const [animateIn, setAnimateIn] = useState(true);
    //const [markup, setMarkup] = useState('<svg >Paste markup here! </svg>')
    const [markup, setMarkup] = useState(defaultSVG)



    /**
     * This code handles the reading text from the clipboard after the user
     * clicks on the "Pasted  from clipboard" button
     */
    const [getClipboardContents, setGetClipboardContents] = useState(false);
    async function getClipboardData() {
        try {
            const text = await navigator.clipboard.readText();
            setMarkup(text)
        } catch (err) {
            console.error('Failed to read clipboard contents: ', err);
        }
    }

    /**
     * We need to keep an eye on getClipboardContents after the 
     * reader as when the user clicks on the "Pasted  from clipboard"
     * button getClipboardContents will be set to true, firing 
     * the  getClipboardData async function
     */
    useEffect(() => {
        if (getClipboardContents) {
            getClipboardData()
            setGetClipboardContents(false)
        }
    }, [getClipboardContents, setGetClipboardContents])



    const updateMarkup = (e) => {
        setMarkup(e.target.value);
        setShowErrorToast(false); // This is here as we need to hide the toast for the next import attempt
    }


    const handleSubmit = (evt) => {
        evt.preventDefault();

        /**
         * Set the  global state importError to false, SVG_IMPORT_SUCCESS
         * will do this but we still need to reset it before an import attempt
         * as the setShowErrorToast in the effect will not update, so the error
         * toast will not show
         */
        dispatch({ type: 'SVG_IMPORT_ERROR', payload: false })

        if (!isSvg(markup)) {  // If markup is not an svg dispatch an error 
            dispatch({ type: 'SVG_IMPORT_ERROR', payload: true });
            return;
        }

        if (!markup.toString().includes("id=")) { // If markup has not ids  in it dispatch an error 
            dispatch({ type: 'SVG_IMPORT_ERROR', payload: true });
            return;
        }

        // Dispatch  SVG_IMPORT_SUCCESS and hide Import popup
        dispatch({ type: 'SVG_IMPORT_SUCCESS', payload: markup});
        setAnimateIn(false); // This will update the state so the popup will animate out
        setTimeout(() => { // setAnimateIn will only hide the popup but wen to stop it from rendering as we want to close it
            setShowImportPopup(false);
        }, 500)
    }

    /**
     * After first render, set AnimateIn to null to shop the animation 
     * of the PopupContainer
     * and PopupBackgroundOverlay, we need to do this as setShowToast 
     * will re-render the component and we don't want it to animate
     */
    useEffect(() => {
        setAnimateIn(null);
    }, [setAnimateIn]);


    // This will keep an eye on is there is an error with the imported SVG Code
    /**
     * This will keep an eye on is there is an error with the imported SVG Code,
     * if globalState.importError is true the toast will be show but after
     * 2.2 seconds it will be hidden
     */
    const [showErrorToast, setShowErrorToast] = useState(false)
    useEffect(() => {
        if (globalState.importError) {
            setShowErrorToast(true);
            let timer1 = setTimeout(() => {
                setShowErrorToast(false)
                dispatch({ type: 'SVG_IMPORT_ERROR', payload: false })
            }, 2200);

            return () => {
                clearTimeout(timer1);
            };
        }
    }, [globalState.importError, setShowErrorToast, dispatch])


    return (
        <div className="grid grid-cols-1 grid-rows-1 w-screen h-screen absolute top-0">
            <PopupContainer animateIn={animateIn}>
                {!globalState.initialSVGImport && // if initialSVGImport is true, the popup is being a accessed from the import button so show the close icon
                    <CloseButton
                        onClick={(evt) => {
                            evt.preventDefault();
                            setAnimateIn(false); // This will update the state so the popup will animate out
                            setTimeout(() => { // setAnimateIn will only hide the popup but wen to stop it from rendering as we want to close it
                                setShowImportPopup(false);
                            }, 500)
                            //setShowExportPopup(false); // this will close the popup
                        }} />
                }
                <PopupHeading>Paste SVG markup</PopupHeading>
                <PopupPara>Make sure the parts of the SVG you wish to animate are in separate groups or layers, check our <PopupLink href={ImportGuide}>guide</PopupLink> if your stuck!</PopupPara>
                <form className="w-full" onSubmit={handleSubmit}>
                    <PopupTextarea type="text" name="markup" value={markup} onChange={updateMarkup} />
                    <div className="flex">
                        <PopupButtonSecondary onClick={(evt) => {
                            evt.preventDefault();
                            setGetClipboardContents(true);
                        }}>
                            <ClipboardIcon className="h-6 w-6 mr-2 text-blueGray-50" />
                            Pasted  from clipboard
                        </PopupButtonSecondary>
                        <PopupSubmitInput value="Start" styleOverride="ml-auto" />
                    </div>
                    <div id="test"></div>
                </form>
            </PopupContainer>
            <PopupBackgroundOverlay animateIn={animateIn} />
            {showErrorToast && <Toast type="error">Ohh no... Looks like there’s something wrong with that file.</Toast>}
        </div>
    )
}

export default ImportPopup;

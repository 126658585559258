import React from 'react';

const PopupButtonSecondary = ({ onClick, children }) => {

    return (
        <button onClick={onClick} className="btn btn-popupSecondary flex items-center self-start mr-3">
            {children}
        </button>
    )
}

export default PopupButtonSecondary;


import React from 'react';
import Logo from '../molecules/Logo/Logo';
import ButtonPrimary from '../atoms/Button/ButtonPrimary';
import ButtonSecondary from '../atoms/Button/ButtonSecondary';

const Header = ({ setShowImportPopup, setShowExportPopup }) => {
    return (
        <header className="flex col-span-3 bg-blueGray-700 px-4 py-3">
            <a href="."><Logo /></a>
            <div className="ml-auto">
                {/* TODO: import button in the header is not working so using a link to refresh the page */}
                <a href=".">
                    <ButtonSecondary onClick={() => {/*setShowImportPopup(true) */ }}>Import</ButtonSecondary>
                </a>
                <ButtonPrimary onClick={() => { setShowExportPopup(true) }}>Export</ButtonPrimary>
            </div>
        </header>
    )
}

export default Header;
import React, { useState } from 'react';
import DisclaimerPopup from '../organisms/DisclaimerPopup';
import ImportPopup from '../organisms/ImportPopup';
import ExportPopup from '../organisms/ExportPopup';
import Header from '../organisms/Header';
import Viewer from '../organisms/Viewer';
import PropertiesPanel from '../organisms/PropertiesPanel';
import Timeline from '../organisms/Timeline';


const AnimationInterface = () => {


    /**
     * Set up state for import and export popups, this will 
     * be used to decide wether or no to show them or not
     */
    const [showDisclaimerPopup, setShowDisclaimerPopup] = useState(true);
    const [showImportPopup, setShowImportPopup] = useState(false);
    const [showExportPopup, setShowExportPopup] = useState(false);


    return (
        <div className="grid grid-cols-animationInterface grid-rows-animationInterface h-screen w-screen">
            <Header setShowImportPopup={setShowImportPopup} setShowExportPopup={setShowExportPopup} />
            <Viewer />
            <PropertiesPanel />
            <Timeline />
            {showDisclaimerPopup && <DisclaimerPopup setShowDisclaimerPopup={setShowDisclaimerPopup} setShowImportPopup={setShowImportPopup} />}
            {showImportPopup && <ImportPopup setShowImportPopup={setShowImportPopup} />}
            {showExportPopup && <ExportPopup setShowExportPopup={setShowExportPopup} />}
        </div>
    )
}

export default AnimationInterface;
import React from 'react';


const KeyFrame = ({ isSelected, positionX, id, setSelectKeyframe, isLocked }) => {

    const handleClick = () => {


        // If parent layer is locked don't do anything and return
        if (isLocked) return;


        /**
         * Toggle keyframe to off it already selected, by
         * passing in null to the setSelectKeyframe
         */
        if (isSelected) {
            setSelectKeyframe(null)
            return;
        }
        setSelectKeyframe(id)
    }

    if (isSelected) {
        return (
            <div
                id={id}
                onClick={handleClick}
                style={{ clipPath: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)', left: positionX, transform: 'translate(-5%, 0)' }}
                className="absolute w-4 h-4 bg-emerald-400 cursor-pointer top-4">
            </div>
        )
    }

    return (
        <div
            id={id}
            onClick={handleClick}
            style={{ clipPath: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)', left: positionX, transform: 'translate(-5%, 0)' }}
            className={`absolute w-4 h-4 ${isLocked ? 'bg-blueGray-500' : 'bg-blueGray-300' }  cursor-pointer top-4`}>
        </div>
    )
}


export default KeyFrame;
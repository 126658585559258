
import { gsap } from "gsap/all";
import getEase from './getEase';
import updateAnimationOnScreen from './updateAnimationOnScreen';

export default function updateAnimationOnScreenAfterDelete(layer, currentTimeIncrement) {

    // If there is  no keyframes in that layer return
    if (layer.keyframes.length < 1) {
        return;
    }


    // Get layer
    let layerId = `${layer.id}`
    const SVGLayerOnScreen = document.getElementById(layerId);

    // If there is only one keyframe reset to default
    if (layer.keyframes.length === 1) {

        // Make new timeline 
        const tl = gsap.timeline();

        tl.to(SVGLayerOnScreen,
            {
                rotation: 0,
                opacity: 1,
                scaleX: 1,
                scaleY: 1,
                x: 0,
                y: 0,
                duration: 0.01,
                ease: getEase('easeNone')
            }
        );

        return;
    }

    // If there are multiple layers update the animation
    const isPlaying = true;
    updateAnimationOnScreen(layer, currentTimeIncrement, isPlaying)


}
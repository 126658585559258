
import { Power2 } from "gsap/all";

export default function getEase(ease) {
    if (ease === 'easeNone') {
        return Power2.easeNone;
    } else if (ease === 'easeIn') {
        return Power2.in;
    } else if (ease === 'easeOut') {
        return Power2.out;
    } else if (ease === 'easeInOut') {
        return Power2.inOut;
    }
}
import React from 'react';
import { Label } from '../../atoms/Text/Text';
import Select from '../../atoms/Input/Select';


const SelectWithLabel = ({ value, onChange, options, children }) => (
    <Label>
        <span className="w-28 text-left">{children}</span>
        <Select value={value} onChange={onChange}  options={options}/>
    </Label>
);

export default SelectWithLabel;
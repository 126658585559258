import React, { useState, useContext, useEffect } from 'react';
import { MainAppContext } from '../../api/context/main.context';
import { Heading, SubHeading } from '../atoms/Text/Text';
import StandardInputWithLabel from '../molecules/Input/StandardInputWithLabel';
import SelectWithLabel from '../molecules/Input/SelectWithLabel';
import SubmitInput from '../atoms/Input/SubmitInput';
import ButtonSecondary from '../atoms/Button/ButtonSecondary';
import { TrashIcon } from '@heroicons/react/outline';
import updateAnimationOnScreen from '../../animations/updateAnimationOnScreen';
import updateAnimationOnScreenAfterDelete from '../../animations/updateAnimationOnScreenAfterDelete';


const PropertiesPanel = () => {

    // Get app context
    const { globalState, dispatch } = useContext(MainAppContext);


    // If keyframe is selected get its properties
    let selectedKeyFrame = globalState.currentKeyframeSelected;
    let selectedKeyFrameProperties;
    if (selectedKeyFrame !== null) {
        globalState.layers.forEach(layer => {
            layer.keyframes.forEach(keyframe => {
                if (keyframe.id === globalState.currentKeyframeSelected) {
                    /**
                     * Set selectedKeyFrameProperties to the properties
                     * from the current selected keyframe 
                     */
                    selectedKeyFrameProperties = keyframe;
                }
            })
        });
    }


    const [rotation, setRotation] = useState(0);
    const [scaleX, setScaleX] = useState(1);
    const [scaleY, setScaleY] = useState(1);
    const [opacity, setOpacity] = useState(1);
    const [translateX, setTranslateX] = useState(0);
    const [translateY, setTranslateY] = useState(0);
    const [ease, setEase] = useState('easeNone');


    /**
     * If the keyframe is selected by the user update the form to 
     * show that keyframes value in the form inputs, otherwise
     * reset the form to default 
     */
    useEffect(() => {
        if (selectedKeyFrameProperties) {
            setRotation(selectedKeyFrameProperties.rotation);
            setScaleX(selectedKeyFrameProperties.scaleX);
            setScaleY(selectedKeyFrameProperties.scaleY);
            setOpacity(selectedKeyFrameProperties.opacity);
            setTranslateX(selectedKeyFrameProperties.translateX);
            setTranslateY(selectedKeyFrameProperties.translateY);
            setEase(selectedKeyFrameProperties.ease);
        } else {
            // Reset form
            setRotation(0);
            setScaleX(1);
            setScaleY(1);
            setOpacity(1);
            setTranslateX(0);
            setTranslateY(0);
            setEase('easeNone');
        }
    }, [globalState.layers, selectedKeyFrameProperties])



    const handleSubmit = (evt) => {
        evt.preventDefault();
        // This is where you will update global state
        dispatch({
            type: 'ADD_KEYFRAME', payload:
            {
                rotation: rotation,
                scaleX: scaleX,
                scaleY: scaleY,
                opacity: opacity,
                translateX: translateX,
                translateY: translateY,
                ease: ease
            }
        });

        // Update the Viewer
        //console.log(globalState)
    
    }

    // This will update the viewer based on the PlayHead position
    useEffect(() => {
      globalState.layers.forEach(layer => {
        const isPlaying = false;
        updateAnimationOnScreen(layer, globalState.currentTimeIncrement, isPlaying);
      })
    }, [globalState.layers, globalState.currentTimeIncrement])

    const updateRotation = (e) => {
        setRotation(e.target.value);
    }

    const updateScaleX = (e) => {
        setScaleX(e.target.value);
    }

    const updateScaleY = (e) => {
        setScaleY(e.target.value);
    }

    const updateOpacity = (e) => {
        setOpacity(e.target.value);
    }

    const updateTranslateX = (e) => {
        setTranslateX(e.target.value);
    }

    const updateTranslateY = (e) => {
        setTranslateY(e.target.value);
    }

    const updateEase = (e) => {
        setEase(e.target.value);
    }



    const deleteKeyframe = () => {

        // Update the animation on screen
        globalState.layers.forEach(layer => {
            updateAnimationOnScreenAfterDelete(layer, globalState.currentTimeIncrement)
        });


        // TODO: delete keyframe
        dispatch({ type: 'DELETE_KEYFRAME', payload: globalState.currentKeyframeSelected });


        
        // Reset form
        setRotation(0);
        setScaleX(1);
        setScaleY(1);
        setOpacity(1);
        setTranslateX(0);
        setTranslateY(0);
        setEase('easeNone');
    }




    return (
        <div className="col-span-1 bg-blueGray-600 p-6">
            <Heading>Properties</Heading>
            <SubHeading>Group 3</SubHeading>
            <form onSubmit={handleSubmit}>
                <StandardInputWithLabel type="text" name="rotation" value={rotation} onChange={updateRotation}> Rotation: </StandardInputWithLabel>
                <StandardInputWithLabel type="text" name="scaleX" value={scaleX} onChange={updateScaleX}> ScaleX: </StandardInputWithLabel>
                <StandardInputWithLabel type="text" name="scaleY" value={scaleY} onChange={updateScaleY}> ScaleY:</StandardInputWithLabel>
                <StandardInputWithLabel type="text" name="opacity" value={opacity} onChange={updateOpacity}>Opacity:</StandardInputWithLabel>
                <StandardInputWithLabel type="text" name="translateX" value={translateX} onChange={updateTranslateX}>TranslateX:</StandardInputWithLabel>
                <StandardInputWithLabel type="text" name="translateY" value={translateY} onChange={updateTranslateY}>TranslateY:</StandardInputWithLabel>
                <SelectWithLabel
                    value={ease}
                    onChange={updateEase}
                    options={['easeNone','easeIn', 'easeOut', 'easeInOut']}
                >Ease:</SelectWithLabel>
                <div className="flex mt-10">
                    {selectedKeyFrame
                        ? <SubmitInput value="Update" />
                        : <SubmitInput value="Add keyframe" />
                    }
                    {selectedKeyFrame &&
                        <ButtonSecondary onClick={deleteKeyframe} hasIcon={true}>
                            <TrashIcon className="h-6 w-6 text-red-400 mr-2" />
                            Delete
                        </ButtonSecondary>
                    }
                </div>
            </form>
        </div>
    )
}

export default PropertiesPanel;
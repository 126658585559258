import React, { useState, useEffect, useContext } from 'react';
import { MainAppContext } from '../../api/context/main.context';
import TimelineScale from '../molecules/Timeline/TimelineScale';
import TimelineLayerMenu from '../molecules/Timeline/TimelineLayerMenu';
import PlayHead from '../atoms/PlayHead/PlayHead';
import TimelineDivider from '../atoms/Divider/TimelineDivider';


const TimeLine = () => {


    // Get app context
    const { globalState, dispatch } = useContext(MainAppContext);


    // This sets the PlayHead positionX based on the globalState.playHeadStartingPosition
    const [positionX, setPositionX] = useState(globalState.playHeadCurrentPosition)
    useEffect(() => {
        setPositionX(globalState.playHeadCurrentPosition)
    }, [globalState.playHeadCurrentPosition])


  
    /**
     * We need to set the TimelineLayerMenu to the same width as the TimelineScale 
     * width ,as it overflows the screen. We can't just hard code it as it may cause errors 
     * in the future, if we ever decide to change the scale as this will change the width of
     * the TimelineScale.
     */
    const [timelineScaleWidth, setTimelineScaleWidth] = useState('');


    // This sets the currentTimeIncrement in the global context
    const [currentTimeIncrement, setCurrentTimeIncrement] = useState(0);
    useEffect(() => {
        dispatch({ type: 'UPDATE_CURRENT_TIMELINE_INCREMENT', payload: currentTimeIncrement });
    }, [currentTimeIncrement, dispatch]);



    /**
     * This is passed down to the TimeLineLayerMenu and then the the TimeLineLayerMenuItem,
     * and allows the user to select different layers, will select the first layer
     * automatically when the SVG is imported 
     */
    let defaultSelectedLayer;
    if(globalState.layers.length > 0 ) {
        defaultSelectedLayer = globalState.layers[0].id;
    }
    const [selectLayer, setSelectLayer] = useState(defaultSelectedLayer);
    useEffect(() => {
        dispatch({ type: 'SELECT_LAYER', payload: selectLayer });
    }, [dispatch, selectLayer])


    /** This allows the user to lock layers */
    const [lockLayer, setLockLayer] = useState(null);
    useEffect(() => {
        dispatch({ type: 'LOCK_LAYER', payload: lockLayer });
    }, [lockLayer, dispatch])




    /**
     * The selectKeyframe state gets passed down to the Keyframes in the 
     * TimelineLayerMenu, then the useEffect allows the user to select a keyframe
     * an sore the selected keyframe in the global context
     */
    const [selectKeyframe, setSelectKeyframe] = useState(null)
    useEffect(() => {
        dispatch({ type: 'SELECT_KEYFRAME', payload: selectKeyframe });
    }, [selectKeyframe, dispatch]);


    /**
     * This will tell the PlayHead to move or stop depending on
     * the animationPlaying in the globalState
     */
    const [isPlaying, setIsPlaying] = useState(false);
    useEffect(() => {
       setIsPlaying(globalState.animationPlaying)
    }, [setIsPlaying, globalState.animationPlaying]);


    /**
     * This will be used to tell the PlayHead is the animation has been fast 
     * forwarded 
     */
    const [fastForward, setFastForward] = useState(false);
    useEffect(() => {
       setFastForward(globalState.fastForwardAnimation);
    }, [globalState.fastForwardAnimation])


 
    return (
        <div className="col-span-3 bg-blueGray-700 overflow-x-auto relative">
            <PlayHead
                positionX={positionX}
                setCurrentTimeIncrement={setCurrentTimeIncrement}
                isPlaying={isPlaying}
                fastForward={fastForward}
            />
            <TimelineScale
                setTimelineScaleWidth={setTimelineScaleWidth}
                setCurrentTimeIncrement={setCurrentTimeIncrement}
                setSelectKeyframe={setSelectKeyframe}
            />
            <TimelineLayerMenu
                layers={globalState.layers}
                setSelectLayer={setSelectLayer}
                setSelectKeyframe={setSelectKeyframe}
                setLockLayer={setLockLayer}
                width={timelineScaleWidth}
            />
            <TimelineDivider />
        </div>
    )
}

export default TimeLine;
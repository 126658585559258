import './index.css';
import { MainAppProvider } from './api/context/main.context'
import AnimationInterface from './gui/templates/AnimationInterface';


function App() {
  return (
    <div className="App">
      <MainAppProvider>
        <AnimationInterface />
      </MainAppProvider>
    </div>
  );
}

export default App;


import React, { useState, useContext, useEffect} from 'react';
import { DocumentDuplicateIcon } from '@heroicons/react/solid';


import { MainAppContext } from '../../api/context/main.context';
import { PopupHeading, PopupPara, PopupLink } from '../atoms/Popup/PopupText';
import PopupContainer from '../atoms/Popup/PopupContainer';
import PopupCodeBox from '../atoms/Popup/PopupCodeBox';
import PopupBackgroundOverlay from '../atoms/Popup/PopupBackgroundOverlay';
import CloseButton from '../atoms/Button/CloseButton';
import PopupButtonPrimary from '../atoms/Button/PopupButtonPrimary';
import Toast from '../molecules/Toast/Toast';
import makeExportAnimation from '../../animations/makeExportAnimation';

import HowToImplementYourAnimatedSVG from '../../documents/HowToImplementYourAnimatedSVG.pdf'




const ExportPopup = ({ setShowExportPopup }) => {


    // Get app context
    const { globalState } = useContext(MainAppContext);


    // Set state so popup can animate out
    const [animateIn, setAnimateIn] = useState(true);

    // State to show toast
    const [showToast, setShowToast] = useState(false)


    /**
    * After first render, set AnimateIn to null to shop the animation 
    * of the PopupContainer
    * and PopupBackgroundOverlay, we need to do this as setShowToast 
    * will re-render the component and we don't want it to animate
    */
    useEffect(() => {
        setAnimateIn(null);
    },[setAnimateIn]);



    return (
        <div className="grid grid-cols-1 grid-rows-1 w-screen h-screen absolute top-0">
            <PopupContainer animateIn={animateIn}>
                <CloseButton
                    onClick={() => {
                        setAnimateIn(false); // This will update the state so the popup will animate out
                        setTimeout(() => { // setAnimateIn will only hide the popup but wen to stop it from rendering as we want to close it
                            setShowExportPopup(false);
                        }, 500)
                        //setShowExportPopup(false); // this will close the popup
                    }} />
                <PopupHeading>Copy animated SVG code</PopupHeading>
                <PopupPara>If you're unsure about how to implement your animation code check out our <PopupLink href={HowToImplementYourAnimatedSVG}>guide!</PopupLink></PopupPara>
                <PopupCodeBox>
                    {/* {globalState.exportSVGCode} */}
                    {makeExportAnimation(globalState.layers, globalState.exportSVGCode)}
                </PopupCodeBox>
                <PopupButtonPrimary
                    onClick={() => {
                        setShowToast(true) // Show toast
                        navigator.clipboard.writeText(makeExportAnimation(globalState.layers, globalState.exportSVGCode)); // Copy the code to the clipboard 
                        setTimeout(() => { // Hide toast after 2.2 seconds
                            setShowToast(false);
                        }, 2200)
                        //console.log('I was clicked')
                    }}>
                    <DocumentDuplicateIcon className="h-6 w-6 mr-2 text-indigo-600" />
                    Copy code
                </PopupButtonPrimary>
            </PopupContainer>
            <PopupBackgroundOverlay animateIn={animateIn} />
            {showToast && <Toast type="info">Code copied to clipboard.</Toast>}
        </div>
    )
}

export default ExportPopup;
